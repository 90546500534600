import React from "react"
import { navigate } from "gatsby"

import useShop from "./ShopContext"
import { _localizeText } from "../../core/utils"

const Login = ({ open }) => {
  const { userStatus } = useShop()
  // console.log({ userStatus })

  const loginLabel = _localizeText("login")
  const myAccountLabel = _localizeText("myAccount")
  return (
    <div className="login">
      {userStatus !== "SignedIn" && (
        <button className="snipcart-customer-signin">
          <span>{loginLabel}</span>
        </button>
      )}
      {userStatus === "SignedIn" && (
        <button onClick={() => navigate(`/my-account`)}>
          {myAccountLabel}
        </button>
      )}
    </div>
  )
}

export default Login
