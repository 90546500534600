import React from "react"
import Mailchimp from "./ui/Mailchimp"
import i18n from "../../config/i18n"
import useLocale from "../contexts/LocaleWrapper"
// import i18n from "../../../config/i18n"

const Footer = () => {
  const { locale } = useLocale()

  return (
    <footer className="p-md">
      <div className="row justify-center md:justify-start">
        <div className="col-md-2 col-xs-12">
          <div className="copyright">© {new Date().getFullYear()} </div>
        </div>
        <div className="col-xs">
          <Mailchimp
            action="https://pizza.us14.list-manage.com/subscribe/post?u=5549f1bf4970dce017ae45e0f&amp;id=363cda5ab0"
            fields={[
              {
                name: "EMAIL",
                placeholder: "Newsletter",
                type: "email",
                required: true,
              },
            ]}
            messages={{
              sending: i18n[locale].sending,
              success: i18n[locale].success,
              error: i18n[locale].error,
              empty: i18n[locale].empty,
              duplicate: i18n[locale].duplicate,
              button: "OK",
            }}
            className="mailchimp"
          />
        </div>
        <div className="col-md-2 col-xs-12">
          <p>
            Made with <span className="text-red">❤</span> by{" "}
            <a
              href="https://ahmedghazi.com"
              target="_blank"
              // className="text-blue"
            >
              a_e_a_i_
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
