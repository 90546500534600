import { navigate } from "gatsby"
import React, { createContext, useState, useContext, useEffect } from "react"

const ShopContext = createContext()
const initialState = {
  ready: false,
  userStatus: "SignedOut",
  cartQuantity: 0,
  cartTotal: 0,
  cartSubTotal: 0,
  products: [],
}

export const ShopWrapper = ({ children }) => {
  const [ready, setReady] = useState(false)
  const [userStatus, setUserStatus] = useState("")
  const [customer, setCustomer] = useState({})
  const [cart, setCart] = useState()
  const [products, setProducts] = useState([])
  // const [Shop, dispatchShop] = useState(initialState);
  const [userFlow, setUserFlow] = useState([])
  const [isCheckingOut, setIsCheckingOut] = useState(false)

  useEffect(() => {
    const { Snipcart } = window
    if (!Snipcart) {
      document.addEventListener("snipcart.ready", e => {
        setReady(true)
      })
    } else {
      setReady(true)
    }

    window.onhashchange = () => {
      // console.log(location.hash)
      if (location.hash.indexOf("dashboard") > -1) {
        navigate("/my-account")
      }
    }
  }, [])

  useEffect(() => {
    if (!ready) return
    // Snipcart.api.configure("show_cart_automatically", false)
    // console.log(Snipcart)

    Snipcart.api.session.setLanguage("en", {
      signin_form: {
        signin: "Login",
      },
    })

    const listenSnipcart = () => {
      const { customer, cart } = Snipcart.store.getState()
      // console.log(Snipcart.store.getState().cart.items.items)
      setCustomer(customer)
      setUserStatus(customer.status)
      setCart(cart)
    }
    // listen store update
    const unsubscribe = Snipcart.store.subscribe(listenSnipcart)

    const registred = Snipcart.events.on("customer.registered", customer => {
      // console.log(`Customer ${customer.email} just created an account.`)
      //nav to checkout
      setTimeout(() => {
        navigate("/#/cart")
      }, 300)
    })

    const signedIn = Snipcart.events.on("customer.signedin", customer => {
      // console.log(`Customer ${customer.email} just signed in.`)
      // navigate("/my-account")
    })

    const routeChanged = Snipcart.events.on(
      "theme.routechanged",
      _onRouteChanged
    )

    // call first
    listenSnipcart()
    return () => {
      unsubscribe()
      routeChanged()
      registred()
      signedIn()
    }
  }, [ready, isCheckingOut, userStatus])

  // useEffect(() => {
  //   console.log({ isCheckingOut })
  //   console.log(userFlow)
  //   if (!isCheckingOut) return

  //   if (
  //     userFlow[userFlow.length - 1] === "/" &&
  //     userFlow[userFlow.length - 2] === "/dashboard"
  //   ) {
  //     //back to /
  //     console.log("ok to redirect to checkout")
  //   } else {
  //     console.log("not ok to redirect to checkout")
  //   }

  // }, [userFlow, isCheckingOut])

  const _onRouteChanged = routesChange => {
    // console.log("routesChange", routesChange)
    // console.log("isCheckingOut", isCheckingOut)
    setUserFlow(prevArray => [...prevArray, routesChange.to])
    // console.log({ userStatus })
    if (routesChange.from === "/cart" && routesChange.to === "/checkout") {
      // console.log("-----------------================**************")
      setIsCheckingOut(true)
    }

    if (routesChange.to === "/" && isCheckingOut) {
      // console.log("ok to redirect to checkout")
      setIsCheckingOut(false)
      // navigate("#/checkout")
      setTimeout(() => {
        Snipcart.api.theme.cart.open()
      }, 250)
    }

    if (routesChange.to === "/checkout") {
      if (userStatus !== "SignedIn") {
        const butonLogin = document.querySelector(".snipcart-customer-signin")
        // console.log(butonLogin)
        if (butonLogin) butonLogin.click()
      }
    }

    if (routesChange.to === "/dashboard") {
      // console.log("on my account")
      // Snipcart.events.publish("theme.routechanged", "/")
      window.location.hash = ""
      // setTimeout(() => {
      //   navigate("/my-account")
      // }, 250)
    }
    if (routesChange.from === "/" && routesChange.to !== "/") {
      // console.log("cart opened")
      const btn = document.querySelector(
        ".snipcart-cart-header__customer-account"
      )
      if (btn) btn.href = "/my-account/"
    }
  }
  return (
    <ShopContext.Provider
      value={{ ready, userStatus, customer, cart, products, setProducts }}
    >
      {children}
    </ShopContext.Provider>
  )
}
export default function useShop() {
  return useContext(ShopContext)
}
