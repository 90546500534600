import { useStaticQuery, graphql } from "gatsby"

export const useSiteMetadata = () => {
  const { site, sanitySettings } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            siteTitle
            siteDescription
            siteUrl
            defaultBanner: banner
            ogLanguage
            author
            twitter
            facebook
          }
        }
        sanitySettings {
          siteName
          # seo {
          #   metaTitle
          #   metaDescription
          #   metaImage {
          #     asset {
          #       url
          #     }
          #   }
          # }
          # siteDescription
          message404: _rawMessage404(resolveReferences: { maxDepth: 10 })
          messageCookie: _rawMessageCookie(resolveReferences: { maxDepth: 10 })
          # contact
        }
      }
    `
  )
  // console.log(global)
  return {
    buildTime: site.buildTime,
    site: site.siteMetadata,
    settings: sanitySettings,
  }
}
