import React from "react"
import jsonp from "jsonp"
import PropTypes from "prop-types"
import clsx from "clsx"

class Mailchimp extends React.Component {
  state = {
    status: "",
    ready: false,
    showSubmit: false,
  }
  componentDidMount() {
    this.setState({
      ready: true,
    })
  }

  validateEmail(name, value) {
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/
    const email = value
    this.setState({ showSubmit: regex.test(email) })
    this.setState({ [name]: value })
  }

  handleSubmit(evt) {
    evt.preventDefault()
    const { fields, action } = this.props
    const values = fields
      .map(field => {
        return `${field.name}=${encodeURIComponent(this.state[field.name])}`
      })
      .join("&")
    const path = `${action}&${values}`
    const url = path.replace("/post?", "/post-json?")
    const regex = /^([\w_\.\-\+])+\@([\w\-]+\.)+([\w]{2,10})+$/
    const email = this.state["EMAIL"]
    !regex.test(email) ? this.setState({ status: "empty" }) : this.sendData(url)
  }

  sendData(url) {
    this.setState({ status: "sending" })
    jsonp(url, { param: "c" }, (err, data) => {
      if (data.msg.includes("already subscribed")) {
        this.setState({ status: "duplicate" })
      } else if (err) {
        this.setState({ status: "error" })
      } else if (data.result !== "success") {
        this.setState({ status: "error" })
      } else {
        this.setState({ status: "success" })
      }
    })
  }

  getButtonMsg() {
    const messages = {
      ...Mailchimp.defaultProps.messages,
      ...this.props.messages,
    }

    const { status } = this.state
    // console.log(status)
    switch (status) {
      case "sending":
        return messages.sending
      case "success":
        return messages.success
      case "duplicate":
        return messages.duplicate
      case "empty":
        return messages.empty
      case "error":
        return messages.error
      default:
        return messages.button
    }
  }

  render() {
    const { fields, styles, className, formClassName, texte, hasToolTip } =
      this.props
    const messages = {
      ...Mailchimp.defaultProps.messages,
      ...this.props.messages,
    }
    // console.log(hasToolTip)
    const { status, ready, showSubmit } = this.state
    return (
      <form
        onSubmit={this.handleSubmit.bind(this)}
        className={`${className} ${formClassName}`}
      >
        <div className="inner flex items-baseline">
          <div className="form-row">
            {fields.map(input => (
              <input
                className="w-auto "
                {...input}
                size={20}
                key={input.name}
                autoComplete="off"
                onChange={({ target }) =>
                  this.validateEmail(input.name, target.value)
                }
                defaultValue={this.state[input.name]}
              />
            ))}
          </div>
          <div className="form-row">
            {status === "" && (
              <button
                disabled={status === "sending" || status === "success"}
                type="submit"
                className={
                  clsx("outline")
                  // "transition-all",
                  // showSubmit
                  //   ? "translate-x-0 opacity-100"
                  //   : "-translate-x-md opacity-0"
                }
              >
                <span>{this.getButtonMsg()}</span>
              </button>
            )}
            {status === "success" && <span>{this.getButtonMsg()}</span>}
          </div>

          {/* <div className="msg-alert">
            {status === "sending" && (
              <p style={styles.sendingMsg}>{messages.sending}</p>
            )}
            {status === "success" && (
              <p style={styles.successMsg}>{messages.success}</p>
            )}
            {status === "duplicate" && (
              <p style={styles.duplicateMsg}>{messages.duplicate}</p>
            )}
            {status === "empty" && (
              <p style={styles.errorMsg}>{messages.empty}</p>
            )}
            {status === "error" && (
              <p style={styles.errorMsg}>{messages.error}</p>
            )}
          </div> */}
        </div>
      </form>
    )
  }
}

Mailchimp.defaultProps = {
  messages: {
    // initial: "Sending...",
    sending: "Sending...",
    success: "Thank you",
    error: "An unexpected internal error has occurred.",
    empty: "You must write an e-mail.",
    duplicate: "Too many subscribe attempts for this email address",
    button: "Subscribe!",
  },
  buttonClassName: "",
  styles: {
    sendingMsg: {
      color: "#0652DD",
    },
    successMsg: {
      color: "#009432",
    },
    duplicateMsg: {
      color: "#EE5A24",
    },
    errorMsg: {
      color: "#ED4C67",
    },
  },
}

Mailchimp.propTypes = {
  action: PropTypes.string,
  messages: PropTypes.object,
  fields: PropTypes.array,
  styles: PropTypes.object,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
}

export default Mailchimp
