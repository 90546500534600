import React, { useEffect, createContext, useContext } from "react"
import { useState } from "react"

const LocaleContext = createContext()

export const LocaleWrapper = ({ children }) => {
  // const LocaleWrapper = ({ children }) => {
  const [locale, dispatch] = useState("en")
  // console.log(locale)

  //detect user lang
  useEffect(() => {
    // const userLang = _detectUserLang();
    // // console.log("userLang:", userLang)
    // dispatch(userLang);
  }, [])

  // const _detectUserLang = () => {
  //   const userLang = navigator.language || navigator.userLanguage;
  //   // console.log(userLang)
  //   return userLang.toLowerCase().indexOf("fr") === -1 ? "en" : "fr";
  // };

  return (
    <LocaleContext.Provider value={{ locale, dispatch }}>
      {children}
    </LocaleContext.Provider>
  )
}

// export default LocaleWrapper;
// export { LocaleContext, LocaleWrapper };

export default function useLocale() {
  return useContext(LocaleContext)
}
