/* global Snipcart:false */
import React, { useEffect, useState, useContext } from "react"
import useShop from "./ShopContext"
// import { SnipcartContext } from "gatsby-plugin-snipcart-advanced/context";
import { _localizeText } from "../../core/utils"
import clsx from "clsx"
import { navigate } from "gatsby"

const Cart = () => {
  const { ready, userStatus, cart } = useShop()
  // const { state } = useContext(SnipcartContext);
  // const { cartQuantity, ready } = state;
  // console.log(ready, userStatus);
  const [count, setCount] = useState(0)
  // const [cartCreated, setCartCreated] = useState(false)
  // console.log(cartCreated)
  useEffect(() => {
    const { Snipcart } = window
    if (!Snipcart) return
    // console.log(Snipcart)
    let cartReady, itemUpdated, routeChanged
    if (ready) {
      cartReady = Snipcart.events.on("cart.created", _onCartCreated)
      itemUpdated = Snipcart.events.on("item.updated", _onItemUpdated)
      // routeChanged = Snipcart.events.on("theme.routechanged", _onRouteChanged)
    }

    return () => {
      if (cartReady) cartReady()
      if (itemUpdated) itemUpdated()
      // if (routeChanged) routeChanged()
    }
  }, [ready, userStatus])

  useEffect(() => {
    if (!cart) return

    setCount(Snipcart.store.getState().cart.items.count)
    // _handleBtnCheckoutGrant()
    _onItemUpdated()
  }, [cart])

  const _onCartCreated = () => {
    // console.log("_onCartCreated")
    // setCartCreated(true)
    // _handleBtnCheckoutGrant()
    _onItemUpdated()
  }

  const _onItemUpdated = cartItem => {
    console.log(cartItem)
    if (cartItem) {
      const { uniqueId, totalPrice } = cartItem
      console.log(uniqueId, totalPrice)
      if (totalPrice === 0) _removeProduct(uniqueId)
    }
    let grant = false
    const products = document.querySelectorAll(
      "#snipcart li.snipcart-item-line"
    )
    // console.log(products)
    for (let i = 0; i < products.length; i++) {
      let itemGrant = false
      const checkbox = products[i].querySelectorAll(".snipcart-checkbox")
      checkbox.forEach(cbx => {
        if (cbx.checked) itemGrant = true
      })
      grant = itemGrant
      // console.log(i, itemGrant)
      if (grant === false) {
        // const checkboxes = products[i].querySelectorAll(".snipcart-checkbox")
        // if (checkboxes) {
        //   checkboxes[0].checked = true
        //   // console.log(checkboxes[0])
        // }
        break
      }
    }

    const buttonCheckout = document.querySelector(
      "#snipcart .snipcart-cart__footer-buttons button"
    )
    // console.log({ grant, buttonCheckout })
    if (!buttonCheckout) return
    if (grant) {
      buttonCheckout.removeAttribute("disabled")
      buttonCheckout.style.visibility = "visible"
    } else {
      buttonCheckout.setAttribute("disabled", true)
      buttonCheckout.style.visibility = "hidden"
    }

    // _handleBtnCheckoutGrant()
  }

  const _removeProduct = async ID => {
    try {
      await Snipcart.api.cart.items.remove(ID)
    } catch (error) {
      console.log(error)
    }
  }

  const _onRouteChanged = routesChange => {
    console.log("routesChange", routesChange.to)
    console.log({ userStatus })
    // if (routesChange.to === "/checkout") {
    //   if (userStatus === "SignedOut") {
    //     const butonLogin = document.querySelector(".snipcart-customer-signin")
    //     if (butonLogin) butonLogin.click()
    //   }
    // }
    if (routesChange.to === "/cart") {
      // if (userStatus === "SignedOut") {
      //   const butonLogin = document.querySelector(".snipcart-customer-signin")
      //   if (butonLogin) butonLogin.click()
      // }
      _onItemUpdated()

      setTimeout(() => {
        _onItemUpdated()
      }, 400)

      setTimeout(() => {
        _onItemUpdated()
      }, 800)
    }
    // if (routesChange.to === "/dashboard") {
    //   console.log("on my account")
    //   // Snipcart.events.publish("theme.routechanged", "/")
    //   window.location.hash = ""
    //   setTimeout(() => {
    //     navigate("/my-account")
    //   }, 250)
    // }
    // if (routesChange.from === "/" && routesChange.to !== "/") {
    //   // console.log("cart opened")
    //   const btn = document.querySelector(
    //     ".snipcart-cart-header__customer-account"
    //   )
    //   if (btn) btn.href = "/my-account/"
    // }

    // if (routesChange.from !== "/" && routesChange.to === "/") {
    //   console.log("cart closed")
    // }
  }

  const _handleBtnCheckoutGrant = () => {
    console.log("_handleBtnCheckoutGrant")

    const items = Snipcart.store.getState().cart.items.items
    // console.log(items)
    let grant = true
    items.forEach(element => {
      // console.log(element)
      if (element.totalPrice === 0) grant = false
    })
    const buttonCheckout = document.querySelector(
      "#snipcart .snipcart-cart__footer-buttons button"
    )
    // console.log({ grant, buttonCheckout })
    if (!buttonCheckout) return
    if (grant) {
      buttonCheckout.removeAttribute("disabled")
      buttonCheckout.style.visibility = "visible"
    } else {
      buttonCheckout.setAttribute("disabled", true)
      buttonCheckout.style.visibility = "hidden"
    }
  }

  return (
    <div className={clsx("cart x")}>
      <button className="snipcart-checkout">
        <span>{_localizeText("cart")}</span>
        {count > 0 && (
          <span className="snipcart-items-count pl-xs"> ({count})</span>
        )}
        {/* <pre>{JSON.stringify(cart)}</pre> */}
      </button>
      {/* <span class="snipcart-total-price"></span> */}
    </div>
  )
}

export default Cart
