import React from "react"
import PortableText from "@sanity/block-content-to-react"
// import SanityImage from "./SanityImage";
// import VideoEmbed from "./ui/VideoEmbed";
import { _localizeField, _linkResolver, _localizeSlug } from "../core/utils"
import Faq from "./modules/Faq"

const SanityTexte = ({ input }) => {
  // console.log(input);
  const serializers = {
    types: {
      block(props) {
        // console.log(props.node.style);
        switch (props.node.style) {
          case "titleUnderline":
            return (
              <div
                className="title-underline pb-xs mb-xs"
                style={{ borderBottom: "1px solid" }}
              >
                {props.children}
              </div>
            )
          default:
            return <p>{props.children}</p>
        }
      },
      image: ({ node }) => {
        // console.log(node);
        return <SanityImage input={node} />
      },
      faq: ({ node }) => {
        // console.log(node);
        return <Faq input={node} />
      },
    },
    marks: {
      internalLink: ({ mark, children }) => {
        // console.log(_localizeSlug(mark.reference));
        // console.log(children);
        // const { slug = {} } = mark;
        // const href = `/${slug.current}`;
        return (
          <Link to={_linkResolver(mark.reference)} className="tdu">
            {children[0]}
          </Link>
        )
      },
      link: ({ mark, children }) => {
        // Read https://css-tricks.com/use-target_blank/
        const { href } = mark
        return (
          <a href={href} target="_blank" rel="noopener" className="tdu">
            {children}
          </a>
        )
      },
    },
  }
  // console.log(input)
  const localizedText = _localizeField(input)
  // console.log(localizedText);
  if (!localizedText) console.log(input)

  return (
    <div className="texte">
      <PortableText blocks={localizedText} serializers={serializers} />
    </div>
  )
}

export default SanityTexte
