import React, { useContext } from "react"
import useLocale from "../../contexts/LocaleWrapper"
// import { LocaleContext } from "../../contexts/LocaleWrapper";
// const locales = require("../../../config/i18n")
import i18n from "../../../config/i18n"

const LocaleSwitcher = () => {
  const { locale, dispatch } = useLocale()
  // const { locale, dispatch } = useContext(LocaleContext);
  // const { locale, dispatch } = _LocaleContext
  // console.log(_LocaleContext)

  const renderLanguages = () => {
    // const alternateLang = Object.values(locales).filter(
    //   (item) => item.locale !== locale
    // )
    return Object.values(i18n).map((item, i) => (
      <li key={`locale-${i.toString()}`} className="flex">
        <button onClick={() => dispatch(item.locale)} className={``}>
          {item.label}
        </button>
        {i === 0 && <span>/</span>}
      </li>
    ))
  }

  return <ul className="locale-switcher flex">{renderLanguages()}</ul>
}

export default LocaleSwitcher
