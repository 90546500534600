import React, { useState } from "react"
import { _localizeField } from "../../core/utils"
import styled from "styled-components"
import clsx from "clsx"
// import chevronN from "../../images/ico-chevron-n.svg";
import chevronS from "../../images/chevron-s.svg"

const Li = styled.li`
  .title {
    border-bottom: var(--border);
  }
  img {
    height: 8px;
    display: block;
    transition: 0.2s transform ease;
  }
  .texte {
    white-space: pre-line;
  }
  &.is-active {
    img {
      transform: rotate(180deg);
    }
    .texte {
      display: block;
    }
  }
`

const FaqItem = ({ input }) => {
  const [active, setActive] = useState(false)
  // console.log(input)
  return (
    <Li className={clsx("pb-sm", active ? "is-active" : "")}>
      <div
        className={clsx("title py-sm cursor-pointer flex justify-between")}
        onClick={() => setActive(!active)}
      >
        <span>{_localizeField(input.title)}</span>
        <img src={chevronS} alt="" height="8" width="" />
      </div>
      <div className={clsx("texte py-sm hidden")}>
        {_localizeField(input.texte)}
      </div>
    </Li>
  )
}

const Faq = ({ input }) => (
  <ul className="faq text-md">
    {input.items.map((item, i) => (
      <FaqItem key={i} input={item} />
    ))}
  </ul>
)

export default Faq
