// import { useContext } from "react"
import locales from "../../config/i18n"
import useLocale from "../contexts/LocaleWrapper"
// import { LocaleContext } from "../contexts/LocaleWrapper";

export const _lerp = (start, end, amt) => (1 - amt) * start + amt * end

export function fileNameByUrl(url) {
  const decoded = decodeURIComponent(url)
  return decoded.substring(decoded.lastIndexOf("/") + 1)
}

export const _slugify = raw =>
  raw
    .toString() // Cast to string
    // .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/&/g, "-y-") // Replace & with 'and'
    .replace(/[^\w\-]+/g, "-") // Remove all non-word chars
    .replace(/\-\-+/g, "-") // Replace multiple - with single -

/**
 * Shuffles array in place. ES6 version
 * @param {Array} a items An array containing the items.
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[a[i], a[j]] = [a[j], a[i]]
  }
  return a
}

// export function _linkResolver(doc) {
//   // console.log(doc);
//   switch (doc._type) {
//     case "product":
//       return `/type/${doc.slug.current}`;

//     default:
//       return `/${doc.slug.current}`;
//   }
// }

export const _getLocale = () => {
  const { locale } = useLocale()
  return locale
  // const { localeCtx } = useContext(LocaleContext);
  // return localeCtx || "fr";
}

export function _localizeField(field) {
  // console.log("_localizeField");
  // console.log(field);
  const locale = _getLocale()
  // console.log(field, locale);
  return field && field[locale] ? field[locale] : ""
}

export const _localizeText = text => {
  const locale = _getLocale()
  return locales[locale] && locales[locale][text] ? locales[locale][text] : text
}

// export const _localizeSlug = (node) => {
//   const locale = _getLocale();
//   // console.log(locales);
//   // console.log(locale);
//   return locales[locale].default
//     ? `/${node.slug.current === "home" ? "" : node.slug.current}`
//     : `/${locale}/${node.slug.current}`;
// };

export const _linkResolver = node => {
  //lang/type/slug
  //lang/slug
  // const locale = _getLocale();

  switch (node._type) {
    case "product":
      return `/type/${node.slug.current}`
    default:
      return `/${node.slug.current}`
  }
}

export const _getHomeUrl = () => {
  const locale = _getLocale()
  return locales[locale].default ? `/` : `/${locales[locale]["path"]}`
}

export const Uint8ToString = u8a => {
  var CHUNK_SZ = 0x8000
  var c = []
  for (var i = 0; i < u8a.length; i += CHUNK_SZ) {
    c.push(String.fromCharCode.apply(null, u8a.subarray(i, i + CHUNK_SZ)))
  }
  return c.join("")
}

// export const _base64ToArrayBuffer = (base64) => {
//   var binary_string = window.atob(base64);
//   var len = binary_string.length;
//   var bytes = new Uint8Array(len);
//   for (var i = 0; i < len; i++) {
//     bytes[i] = binary_string.charCodeAt(i);
//   }
//   return bytes.buffer;
// };

export const getCookie = cname => {
  let name = cname + "="
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(";")
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) == " ") {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ""
}

// export const _slugify = input => {
//   const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
//   const to = "aaaaaeeeeeiiiiooooouuuunc------"

//   const newText = input
//     .split("")
//     .map((letter, i) =>
//       letter.replace(new RegExp(from.charAt(i), "g"), to.charAt(i))
//     )

//   return newText
//     .toString() // Cast to string
//     .toLowerCase() // Convert the string to lowercase letters
//     .trim() // Remove whitespace from both sides of a string
//     .replace(/\s+/g, "-") // Replace spaces with -
//     .replace(/&/g, "-y-") // Replace & with 'and'
//     .replace(/[^\w\-]+/g, "") // Remove all non-word chars
//     .replace(/\-\-+/g, "-") // Replace multiple - with single -
// }
function getRandomNumber(max = 100) {
  return Math.floor(Math.random() * max)
}

export const arrayOfNumber = len => {
  return Array.from({ length: len }, () => getRandomNumber())
}

export const _dateFr = d => {
  const _d = new Date(d)
  return _d.toLocaleDateString("fr")
}
